module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kollosche","short_name":"kollosche","start_url":"/","background_color":"#0B152C","theme_color":"#0B152C","display":"minimal-ui","icon":"src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6c8443c787a929fd92b0957780225314"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://kollosche.codedrips.com/graphql","searchAndReplace":[{"search":"https://kollosche.codedrips.com","replace":"https://kollosche-1bfb7.kxcdn.com"},{"search":"https://kollosche.com.au/wp-content/uploads","replace":"https://kollosche-1bfb7.kxcdn.com/wp-content/uploads"}],"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":false},"type":{"MediaItem":{"createFileNodes":false,"localFile":{"requestConcurrency":100,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Property":{"limit":null},"Rental":{"limit":null},"Video":{"limit":null},"Post":{"limit":null},"Tag":{"limit":null},"Feature":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"timeout":60000,"requestConcurrency":50,"previewRequestConcurrency":50,"perPage":100,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5HSN62Q","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
